@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap');

@mixin styling($section-width) {
  $menu-width: 100px;
  $map-position: calc((100% - #{$section-width} - #{$menu-width}) / 2 + #{$section-width} + #{$menu-width});
  $map-size: 3500px;

  @keyframes rotating {
    from {
      background-position: left top;
    }
    to {
      background-position: 100% top;
    }
  }

  #root {
    height: 100%;
  }

  .forbidden {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }

  .landing {
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    height: 100%;
    position: relative;
    overflow: hidden;

    .sideMenu {
      position: fixed;
      top: 0;
      bottom: 0;
      left: -100vw;
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.9);
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      color: white;
      padding-bottom: 60px;
      transition: 1s, position 1s;

      &.active {
        left: 0;
      }

      .item {
        padding: 20px 0 20px 0;
        padding-left: clamp(1px, 20vw, 120px);
        background-size: clamp(1px, 10vw, 40px);
        background-position: 5vw 12px;
        background-repeat: no-repeat;
      }

      .back {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 40px;

        img {
          width: 100%;
        }
      }
    }

    .sidebar {
      position: absolute;
      top: 0;
      left: -($section-width + $menu-width);
      bottom: 0;
      transition: 1s;
      display: flex;

      &.show {
        left: 0;
      }

      .menu {
        width: $menu-width;
        background-color: #2E6E96;
        display: flex;
        flex-direction: column;
        color: white;
        text-align: center;
        padding: 10vh 0;

        .item {
          flex: 1;
          cursor: pointer;
          background-size: clamp(1px, 60%, 70px);
          background-position: center;
          opacity: 0.4;
          background-repeat: no-repeat;

          &.active, &:hover, &:active {
            opacity: 1;
          }
        }

        .prev, .next {
          background-color: #51b1f0;
          height: 50px;
          line-height: 50px;
          cursor: pointer;
          overflow: hidden;

          span {
            transform: scale(2, 1);
            display: block;
          }
        }
      }

      .section {
        width: $section-width;
        background-color: #7bb9d1;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 5%;

        .title {
          margin-bottom: 10px;
          text-align: center;
          font-size: clamp(10px, 22px, 3vw);
        }
      }
    }

    .objects {
      width: $map-size;
      height: $map-size;
      transition: 2s, position 2s;
      position: absolute;
      left: 0;
      top: 0;
      //background-color: rgba(255,0,0,0.5);

      .pin {
        position: absolute;
        width: 2.3%;
        display: inline-block;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
        cursor: pointer;

        img {
          width: 100%;
        }
      }

      .animation {
        position: absolute;
        width: 1.5%;
        display: inline-block;
      }

      .video {
        position: absolute;
        width: 1.5%;
        display: inline-block;
        cursor: pointer;
      }
    }

    .map {
      width: $map-size;
      height: $map-size;
      transition: 2s, background-position 2s;
      position: absolute;
      left: $map-position;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-size: 100%;
      background-repeat: no-repeat;
      background-color: #57b95a;
      background-image: url("../../assets/images/background_large.jpg");
      background-position: center;
    }

    .dot {
      position: absolute;
      left: $map-position;
      //left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 10px;
      height: 10px;
      background-color: red;
      border-radius: 30px;
      z-index: 1;
    }

    .videoPopup {
      position: fixed;
      left: 0%;
      top: 0%;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .videoContainer {
        width: 1000px;
        max-width: 90%;
        position: relative;

        .videoSelector {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          background-color: rgba(255, 255, 255, 0.8);
          box-shadow: 0px 0px 20px 10px #333;
          padding: 10px;
          border-radius: 25px;
          align-items: center;

          .thumbnail {
            flex-basis: 33%;
            padding: 10px;
            cursor: pointer;
            border-radius: 25px;
          }
        }

        .back {
          position: absolute;
          right: -25px;
          top: -25px;
          width: 50px;
          z-index: 1;
          cursor: pointer;
        }

        video {
          box-shadow: 0px 0px 20px 10px #333;
        }
      }
    }

    .popup {
      position: absolute;
      left: $map-position;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 357px;
      height: 373px;
      background-image: url("../../assets/images/bubble_base.png");
      border-radius: 30px;
      z-index: 2;

      .title {
        margin: 90px 20% 0px;
        font-size: 30px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 110px;
        flex-direction: column;
      }

      .buttons {
        margin: 20px 20% 0px;
        display: flex;
        justify-content: space-around;

        img {
          cursor: pointer;
        }
      }
    }

    .button {
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      height: 50px;
      width: 150px;
      text-align: center;
      border-radius: 30px;
      //background-color: #000;
      cursor: pointer;
      outline: none;
      padding: 0px 28px;
      color: #FFF;
      font-family: "Outfit";
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      line-height: 50px;
      letter-spacing: 3px;
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity 2s linear;

      img {
        width: 100%;
      }

      &.hide {
        visibility: hidden;
        opacity: 0;
      }
    }

    .copyright {
      position: absolute;
      bottom: 10px;
      color: white;
      left: 20px;
    }

    .modal {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;

      p {
        width: 40%;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: 0px 0px 20px 10px #333;
        padding: 30px;
        border-radius: 25px;
        position: relative;

        img {
          position: absolute;
          right: -40px;
          top: -40px;
          width: 50px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 1170px) {
  @include styling($section-width: 300px);
}
@media screen and (min-width: 1171px) {
  @include styling($section-width: 380px);
}
