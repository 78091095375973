$map-size: 1800px;
$menu-section-width: 250px;

@media only screen and (orientation:portrait) {
  .landing{
    .map{
      width: $map-size;
      height: $map-size;
      left: 50%;
      top: calc((100% - #{$menu-section-width})/2);
      transition: 1s, background-position 1s;
    }

    .videoPopup{
      .videoContainer{
        width: 100%;
        max-width: 80%;

        &.list{
          height: 90%;
        }
        .videoSelector{
          overflow-y: auto;
          height: 100%;

          .thumbnail{
            width: 100%;
            max-height: 23%;
          }
        }
      }
    }

    .objects{
      transition: 1s, position 1s;
      width: $map-size;
      height: $map-size;
    }

    .dot{
      left: 50%;
      top: calc((100% - #{$menu-section-width})/2);
    }

    .popup{
      left: 50%;
      top: 50%;
    }

    .sidebar{
      left: 0;
      top: unset;
      right: 0;
      height:0px;
      flex-direction: column;

      &.show{
        height: auto;
        //height: $menu-section-width;
      }

      .menu{
        width: 100%;
        order: 1;
        flex-direction: row;
        flex-basis: 80px;
        padding: 0 0 20px 0;

        .item{
          background-size: 3.5vh;
        }

        .prev, .next{
          height: 100%;
          width: 30px;
          line-height: 100px;

          span {
            transform: rotate(-90deg) scale(2, 1);
          }
        }
      }

      .section{
        width: 100%;
        flex:1;
        font-size: clamp(14px, 3vw, 20px);
        padding-top:15px;
        padding-bottom:15px;

        .title{
          font-size: clamp(16px, 4vw, 25px);
        }
      }
    }

    .copyright{
      bottom: 5px;
      left: 0;
      text-align: center;
      width: 100%;
      font-size: clamp(10px, 3vw, 14px);
    }

    .modal{
      p{
        width: 90%;

        img{
          right: -15px;
          top: -20px;
        }
      }
    }
  }
}
